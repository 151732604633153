import adminAxios from './adminAxios';

export const getLogins = async () => {
  const response = await adminAxios.get(`/logins`);
  return response.data;
};

export const createLogin = async (login: any) => {
  const response = await adminAxios.post(`/logins`, login);
  return response.data;
};

export const updateLogin = async (id: number, login: any) => {
  const response = await adminAxios.put(`/logins/${id}`, login);
  return response.data;
};

export const deleteLogin = async (id: number) => {
  const response = await adminAxios.delete(`/logins/${id}`);
  return response.data;
};
import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, InputAdornment, Snackbar,
  Alert, CircularProgress, TablePagination, Tooltip, useMediaQuery, useTheme, TableSortLabel,
  Avatar, List, ListItem, ListItemAvatar, ListItemText
} from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { getClients, createClient, updateClient, deleteClient, searchWeixinUsers } from '../../services/clientService';
import Layout from '../../components/Layout';

interface Client {
  clientid: string;
  unionid: string;
  disabled: boolean;
  login_vip: boolean;
  login_vip_expire_time: string;
  login_quota: number;
  login_quota_used: number;
  login_domains: string;
  pay_quota: number;
  pay_used: number;
  pay_count: number;
  pay_domains: string;
  nickname: string;
  headimgurl: string;
}

type Order = 'asc' | 'desc';

interface WeixinUser {
  unionid: string;
  nickname: string;
  headimgurl: string;
}

const WeixinUserSelector: React.FC<{
  onSelect: (user: WeixinUser) => void;
  onClose: () => void;
}> = ({ onSelect, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState<WeixinUser[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    if (searchTerm.trim() === '') return;
    setLoading(true);
    try {
      const result = await searchWeixinUsers(searchTerm);
      setUsers(result);
    } catch (error) {
      console.error('搜索微信用户失败:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>选择微信用户</DialogTitle>
      <DialogContent>
        <TextField
          label="搜索微信用户"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <List>
            {users.map((user) => (
              <ListItem
              key={user.unionid}
              onClick={() => onSelect(user)}
              component="div"
            >
              <ListItemAvatar>
                <Avatar src={user.headimgurl} />
              </ListItemAvatar>
                <ListItemText primary={user.nickname} secondary={user.unionid} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

const Clients: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);
  const [open, setOpen] = useState(false);
  const [currentClient, setCurrentClient] = useState<Client | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Client>('clientid');
  const [showUserSelector, setShowUserSelector] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchClients = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getClients();
      setClients(data);
      setFilteredClients(data);
    } catch (error) {
      setError('获取客户端失败');
      console.error('获取客户端失败:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    const filtered = clients.filter(client =>
      client.clientid.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.unionid.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.nickname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.login_domains.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.pay_domains.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
    setPage(0);
  }, [searchTerm, clients]);

  const handleRequestSort = (property: keyof Client) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedClients = React.useMemo(() => {
    const comparator = (a: Client, b: Client) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };

    return [...filteredClients].sort((a, b) => {
      return order === 'desc' ? comparator(a, b) : -comparator(a, b);
    });
  }, [filteredClients, order, orderBy]);

  const handleOpen = (client: Client | null) => {
    setCurrentClient(client || {
      clientid: '',
      unionid: '',
      disabled: false,
      login_vip: false,
      login_vip_expire_time: '',
      login_quota: 0,
      login_quota_used: 0,
      login_domains: '',
      pay_quota: 0,
      pay_used: 0,
      pay_count: 0,
      pay_domains: '',
      nickname: '',
      headimgurl: ''
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentClient(null);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currentClient) {
      setLoading(true);
      try {
        const clientData = {
          unionid: currentClient.unionid,
          disabled: currentClient.disabled,
          login_vip: currentClient.login_vip,
          login_vip_expire_time: currentClient.login_vip_expire_time,
          login_quota: currentClient.login_quota,
          login_domains: currentClient.login_domains,
          pay_quota: currentClient.pay_quota,
          pay_domains: currentClient.pay_domains,
        };

        if (currentClient.clientid) {
          await updateClient(currentClient.unionid, clientData);
          setSnackbar({ open: true, message: '客户端更新成功', severity: 'success' });
        } else {
          await createClient(clientData);
          setSnackbar({ open: true, message: '客户端创建成功', severity: 'success' });
        }
        fetchClients();
        handleClose();
      } catch (error) {
        console.error('保存客户端失败:', error);
        setSnackbar({ open: true, message: '保存客户端失败', severity: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteConfirm = (unionid: string) => {
    setClientToDelete(unionid);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    if (clientToDelete) {
      setLoading(true);
      try {
        await deleteClient(clientToDelete);
        fetchClients();
        setSnackbar({ open: true, message: '客户端删除成功', severity: 'success' });
      } catch (error) {
        console.error('删除客户端失败:', error);
        setSnackbar({ open: true, message: '删除客户端失败', severity: 'error' });
      } finally {
        setLoading(false);
        setDeleteConfirmOpen(false);
        setClientToDelete(null);
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 定义固定“操作”列的样式
  const operationsCellStyle = {
    position: 'sticky' as const,
    right: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
  };

  if (loading && clients.length === 0) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Layout title="客户端管理">
      <Typography variant="h4" gutterBottom>
        客户端管理
      </Typography>
      <Paper sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          label="搜索客户端"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(null)}
          startIcon={<AddIcon />}
        >
          添加客户端
        </Button>
      </Paper>
      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel style={{ whiteSpace: 'nowrap' }}
                  active={orderBy === 'clientid'}
                  direction={orderBy === 'clientid' ? order : 'asc'}
                  onClick={() => handleRequestSort('clientid')}
                >
                  ClientID
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>头像</TableCell>
              <TableCell>
                <TableSortLabel style={{ whiteSpace: 'nowrap' }}
                  active={orderBy === 'nickname'}
                  direction={orderBy === 'nickname' ? order : 'asc'}
                  onClick={() => handleRequestSort('nickname')}
                >
                  昵称
                </TableSortLabel>
              </TableCell>
              {!isMobile && (
                <>
                  <TableCell>
                    <TableSortLabel style={{ whiteSpace: 'nowrap' }}
                      active={orderBy === 'disabled'}
                      direction={orderBy === 'disabled' ? order : 'asc'}
                      onClick={() => handleRequestSort('disabled')}
                    >
                      状态
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel style={{ whiteSpace: 'nowrap' }}
                      active={orderBy === 'login_vip'}
                      direction={orderBy === 'login_vip' ? order : 'asc'}
                      onClick={() => handleRequestSort('login_vip')}
                    >
                      登录VIP
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel style={{ whiteSpace: 'nowrap' }}
                      active={orderBy === 'login_vip_expire_time'}
                      direction={orderBy === 'login_vip_expire_time' ? order : 'asc'}
                      onClick={() => handleRequestSort('login_vip_expire_time')}
                    >
                      VIP过期时间
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel style={{ whiteSpace: 'nowrap' }}
                      active={orderBy === 'login_quota'}
                      direction={orderBy === 'login_quota' ? order : 'asc'}
                      onClick={() => handleRequestSort('login_quota')}
                    >
                      登录配额
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>登录域名</TableCell>
                  <TableCell>
                    <TableSortLabel style={{ whiteSpace: 'nowrap' }}
                      active={orderBy === 'pay_quota'}
                      direction={orderBy === 'pay_quota' ? order : 'asc'}
                      onClick={() => handleRequestSort('pay_quota')}
                    >
                      支付配额
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel style={{ whiteSpace: 'nowrap' }}
                      active={orderBy === 'pay_count'}
                      direction={orderBy === 'pay_count' ? order : 'asc'}
                      onClick={() => handleRequestSort('pay_count')}
                    >
                      支付次数
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>支付域名</TableCell>
                </>
              )}
              <TableCell style={{ whiteSpace: 'nowrap', ...operationsCellStyle }}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client) => (
              <TableRow key={client.clientid}>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{client.clientid}</TableCell>
                <TableCell>
                  <Avatar src={client.headimgurl} />
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>{client.nickname}</TableCell>
                {!isMobile && (
                  <>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{client.disabled ? '禁用' : '启用'}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{client.login_vip ? '是' : '否'}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{client.login_vip_expire_time}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{`${client.login_quota_used} / ${client.login_quota}`}</TableCell>
                    <TableCell>{client.login_domains}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{`${client.pay_used} / ${client.pay_quota}`}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{client.pay_count}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{client.pay_domains}</TableCell>
                  </>
                )}
                <TableCell style={{ whiteSpace: 'nowrap', ...operationsCellStyle }}>
                  <Tooltip title="编辑">
                    <IconButton
                      onClick={() => handleOpen(client)}
                      size="small"
                      aria-label={`编辑 ${client.nickname || client.clientid}`}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="删除">
                    <IconButton
                      onClick={() => handleDeleteConfirm(client.unionid)}
                      size="small"
                      aria-label={`删除 ${client.nickname || client.clientid}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredClients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{currentClient?.clientid ? '编辑客户端' : '添加客户端'}</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="ClientID"
              fullWidth
              value={currentClient?.clientid || ''}
              disabled
            />
            {!currentClient?.clientid && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowUserSelector(true)}
                startIcon={<SearchIcon />}
                sx={{ mt: 2 }}
              >
                选择微信用户
              </Button>
            )}
            {currentClient?.unionid && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                <Avatar src={currentClient.headimgurl} style={{ marginRight: '8px' }} />
                <Typography>{currentClient.nickname}</Typography>
              </div>
            )}
            <TextField
              margin="dense"
              label="UnionID"
              fullWidth
              value={currentClient?.unionid || ''}
              disabled
            />
            <TextField
              margin="dense"
              label="状态"
              select
              fullWidth
              value={currentClient?.disabled ? 'disabled' : 'enabled'}
              onChange={(e) => setCurrentClient({ ...currentClient, disabled: e.target.value === 'disabled' } as Client)}
              SelectProps={{
                native: true,
              }}
            >
              <option value="enabled">启用</option>
              <option value="disabled">禁用</option>
            </TextField>
            <TextField
              margin="dense"
              label="登录VIP"
              select
              fullWidth
              value={currentClient?.login_vip ? 'true' : 'false'}
              onChange={(e) => setCurrentClient({ ...currentClient, login_vip: e.target.value === 'true' } as Client)}
              SelectProps={{
                native: true,
              }}
            >
              <option value="true">是</option>
              <option value="false">否</option>
            </TextField>
            <TextField
              margin="dense"
              label="VIP过期时间"
              type="datetime-local"
              fullWidth
              value={currentClient?.login_vip_expire_time || ''}
              onChange={(e) => setCurrentClient({ ...currentClient, login_vip_expire_time: e.target.value } as Client)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              label="登录配额"
              type="number"
              fullWidth
              value={currentClient?.login_quota || 0}
              onChange={(e) => setCurrentClient({ ...currentClient, login_quota: parseInt(e.target.value) } as Client)}
            />
            <TextField
              margin="dense"
              label="登录域名"
              fullWidth
              value={currentClient?.login_domains || ''}
              onChange={(e) => setCurrentClient({ ...currentClient, login_domains: e.target.value } as Client)}
            />
            <TextField
              margin="dense"
              label="支付配额"
              type="number"
              fullWidth
              value={currentClient?.pay_quota || 0}
              onChange={(e) => setCurrentClient({ ...currentClient, pay_quota: parseInt(e.target.value) } as Client)}
            />
            <TextField
              margin="dense"
              label="支付域名"
              fullWidth
              value={currentClient?.pay_domains || ''}
              onChange={(e) => setCurrentClient({ ...currentClient, pay_domains: e.target.value } as Client)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>取消</Button>
            <Button type="submit" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : '保存'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {showUserSelector && (
        <WeixinUserSelector
          onSelect={(user) => {
            setCurrentClient({ ...currentClient, unionid: user.unionid, nickname: user.nickname, headimgurl: user.headimgurl } as Client);
            setShowUserSelector(false);
          }}
          onClose={() => setShowUserSelector(false)}
        />
      )}
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <Typography>您确定要删除这个客户端吗？此操作不可撤销。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>取消</Button>
          <Button onClick={handleDelete} color="error">
            删除
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Clients;
import axios, { AxiosInstance } from 'axios';

const API_URL = '/api/admin';

let navigate: (to: string) => void;

export const setNavigate = (navigateFunc: (to: string) => void) => {
  navigate = navigateFunc;
};

const adminAxios: AxiosInstance = axios.create({
  baseURL: API_URL,
});

adminAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      if (navigate) {
        navigate('/admin/login');
      } else {
        console.error('导航函数未设置');
      }
    }
    return Promise.reject(error);
  }
);

adminAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default adminAxios;
import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Login from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import WeixinUsers from './pages/admin/WeixinUsers';
import Logins from './pages/admin/Logins';
import Clients from './pages/admin/Clients';
import Orders from './pages/admin/Orders';
import { setNavigate } from './services/adminAxios';
import Home from './pages/Home'; // 新增Home组件
import CreateClient from './pages/CreateClient'; // 新增CreateClient组件

const AppContent: React.FC = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  return (
    <Routes>
      <Route path="/admin/login" element={<Login />} />
      <Route path="/admin" element={<Navigate to="/admin/dashboard" />}/>
      <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/weixin-users" element={<WeixinUsers/>} />
      <Route path="/admin/logins" element={<Logins />}/>
      <Route path="/admin/clients" element={<Clients />}/>
      <Route path="/admin/orders" element={<Orders />}/>
      <Route path="/" element={<Home />} />
      <Route path="/create-client" element={<CreateClient />} />
    </Routes>
  );
};

export default AppContent;
import adminAxios from './adminAxios';

export const getWeixinUsers = async () => {
  const response = await adminAxios.get(`/weixin-users`);
  return response.data;
};

export const createWeixinUser = async (user: any) => {
  const response = await adminAxios.post(`/weixin-users`, user);
  return response.data;
};

export const updateWeixinUser = async (unionid: string, user: any) => {
  const response = await adminAxios.put(`/weixin-users/${unionid}`, user);
  return response.data;
};

export const deleteWeixinUser = async (unionid: string) => {
  const response = await adminAxios.delete(`/weixin-users/${unionid}`);
  return response.data;
};
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CreateClient: React.FC = () => {
  const [clientName, setClientName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post('/api/clients', { name: clientName });
      navigate('/');
    } catch (error) {
      console.error('创建客户失败:', error);
    }
  };

  return (
    <div>
      <h1>创建客户账户</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
          placeholder="输入客户名称"
          required
        />
        <button type="submit">创建</button>
      </form>
    </div>
  );
};

export default CreateClient;
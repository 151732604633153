import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TextField, IconButton, InputAdornment, Snackbar, Alert, CircularProgress, TablePagination,
  Tooltip, TableSortLabel, Avatar,
  Accordion, AccordionSummary, AccordionDetails, Grid, Box
} from '@mui/material';
import { Search as SearchIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { getOrders, deleteOrder } from '../../services/orderService';
import Layout from '../../components/Layout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Order {
  id: number;
  client_id: string;
  client_avatar: string;
  client_nickname: string;
  order_id: string;
  out_trade_no: string;
  amount: number;
  payer_avatar: string;
  payer_nickname: string;
  product_title: string;
  product_desc: string;
  inject_html: string;
  return_url: string;
  trade_state: string;
  created_at: string;
  payer_unionid: string; // 新增字段
}

type OrderSort = 'asc' | 'desc';

const fieldLabels: { [key: string]: string } = {
  client_nickname: '客户昵称',
  payer_nickname: '付款人昵称',
  amount: '金额（元）',
  trade_state: '交易状态',
  order_id: '订单编号',
  product_desc: '商品描述',
  inject_html: '注入HTML',
  product_title: '商品标题',
  client_id: '客户ID',
  out_trade_no: '外部交易号',
  payer_unionid: '付款人UnionID',
};

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState<number | null>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const [order, setOrder] = useState<OrderSort>('desc');
  const [orderBy, setOrderBy] = useState<keyof Order>('created_at');
  const [totalCount, setTotalCount] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const advancedSearchButtonRef = useRef<HTMLButtonElement>(null);
  const [advancedSearch, setAdvancedSearch] = useState({
    order_id: '',
    client_id: '',
    client_nickname: '',
    payer_nickname: '',
    trade_state: '',
    out_trade_no: '',
    amount: '',
    product_title: '',
    product_desc: '',
    inject_html: '',
    payer_unionid: '',
  });
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getOrders(page, rowsPerPage, orderBy, order, searchTerm);
      setOrders(data.orders);
      setTotalCount(data.totalCount);
    } catch (err) {
      setError('获取订单失败');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, orderBy, order, searchTerm]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleRequestSort = (property: keyof Order) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    // 清空高级搜索
    setAdvancedSearch({
      order_id: '',
      client_id: '',
      client_nickname: '',
      payer_nickname: '',
      trade_state: '',
      out_trade_no: '',
      amount: '',
      product_title: '',
      product_desc: '',
      inject_html: '',
      payer_unionid: '',
    });
  };

  const handleSearchSubmit = () => {
    setSearchTerm(searchInput);
    setPage(0);
    // 清空高级搜索
    setAdvancedSearch({
      order_id: '',
      client_id: '',
      client_nickname: '',
      payer_nickname: '',
      trade_state: '',
      out_trade_no: '',
      amount: '',
      product_title: '',
      product_desc: '',
      inject_html: '',
      payer_unionid: '',
    });
  };

  const handleAdvancedSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'amount') {
      // 只允许输入数字和小数点，且小数点后最多两位
      const regex = /^\d*\.?\d{0,2}$/;
      if (regex.test(value) || value === '') {
        setAdvancedSearch({...advancedSearch, [name]: value});
      }
    } else {
      setAdvancedSearch({...advancedSearch, [name]: value});
    }
    // 清空模糊搜索
    setSearchInput('');
  };

  const handleAdvancedSearchSubmit = () => {
    const searchParams = Object.entries(advancedSearch)
      .filter(([_, value]) => value !== '')
      .map(([key, value]) => `${key}:${value}`)
      .join(' AND ');
    console.log('Advanced Search Params:', searchParams);
    setSearchTerm(searchParams);
    setPage(0);
    // 清空模糊搜索
    setSearchInput('');
  };

  const handleDeleteConfirm = (id: number) => {
    setOrderToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    if (orderToDelete) {
      try {
        await deleteOrder(orderToDelete);
        setSnackbar({ open: true, message: '订单删除成功', severity: 'success' });
        fetchOrders();
      } catch (error) {
        setSnackbar({ open: true, message: '订单删除失败', severity: 'error' });
      }
    }
    setDeleteConfirmOpen(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent, isAdvancedSearch: boolean) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isAdvancedSearch) {
        advancedSearchButtonRef.current?.click();
      } else {
        searchButtonRef.current?.click();
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Layout title="订单管理">
      <Paper sx={{ mb: 2, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="搜索订单..."
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyPress={(e) => handleKeyPress(e, false)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            ref={searchButtonRef}
            variant="contained"
            color="primary"
            onClick={handleSearchSubmit}
            sx={{ ml: 2, height: '56px' }}
          >
            搜索
          </Button>
        </Box>
        <Accordion 
          expanded={isAdvancedSearchOpen} 
          onChange={() => setIsAdvancedSearchOpen(!isAdvancedSearchOpen)}
          sx={{ mt: 2 }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>高级搜索</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {Object.entries(advancedSearch).map(([key, value]) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <TextField
                    fullWidth
                    name={key}
                    label={fieldLabels[key]}
                    value={value}
                    onChange={handleAdvancedSearchChange}
                    onKeyPress={(e) => handleKeyPress(e, true)}
                    helperText={key === 'amount' ? '请输入金额，单位为元' : ''}
                  />
                </Grid>
              ))}
            </Grid>
            <Button
              ref={advancedSearchButtonRef}
              variant="contained"
              color="primary"
              onClick={handleAdvancedSearchSubmit}
              sx={{ mt: 2 }}
            >
              高级搜索
            </Button>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ whiteSpace: 'nowrap' }}>客户头像</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'client_nickname'}
                  direction={orderBy === 'client_nickname' ? order : 'asc'}
                  onClick={() => handleRequestSort('client_nickname')}
                >
                  客户昵称
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>付款人头像</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'payer_nickname'}
                  direction={orderBy === 'payer_nickname' ? order : 'asc'}
                  onClick={() => handleRequestSort('payer_nickname')}
                >
                  付款人昵称
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'amount'}
                  direction={orderBy === 'amount' ? order : 'asc'}
                  onClick={() => handleRequestSort('amount')}
                >
                  金额（元）
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'trade_state'}
                  direction={orderBy === 'trade_state' ? order : 'asc'}
                  onClick={() => handleRequestSort('trade_state')}
                >
                  交易状态
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('created_at')}
                >
                  创建时间
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'order_id'}
                  direction={orderBy === 'order_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('order_id')}
                >
                  订单编号
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'product_desc'}
                  direction={orderBy === 'product_desc' ? order : 'asc'}
                  onClick={() => handleRequestSort('product_desc')}
                >
                  商品描述
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'inject_html'}
                  direction={orderBy === 'inject_html' ? order : 'asc'}
                  onClick={() => handleRequestSort('inject_html')}
                >
                  注入HTML
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'product_title'}
                  direction={orderBy === 'product_title' ? order : 'asc'}
                  onClick={() => handleRequestSort('product_title')}
                >
                  商品标题
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>客户ID</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>外部交易号</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <TableSortLabel
                  active={orderBy === 'payer_unionid'}
                  direction={orderBy === 'payer_unionid' ? order : 'asc'}
                  onClick={() => handleRequestSort('payer_unionid')}
                >
                  付款人UnionID
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ position: 'sticky', right: 0, background: 'white', whiteSpace: 'nowrap' }}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>
                  <Avatar src={order.client_avatar} alt={order.client_nickname} />
                </TableCell>
                <TableCell>{order.client_nickname}</TableCell>
                <TableCell>
                  <Avatar src={order.payer_avatar} alt={order.payer_nickname} />
                </TableCell>
                <TableCell>{order.payer_nickname}</TableCell>
                <TableCell>{(order.amount / 100).toFixed(2)}</TableCell>
                <TableCell>{order.trade_state}</TableCell>
                <TableCell>{new Date(order.created_at).toLocaleString()}</TableCell>
                <TableCell>{order.order_id}</TableCell>
                <TableCell>{order.product_desc}</TableCell>
                <TableCell>{order.inject_html}</TableCell>
                <TableCell>{order.product_title}</TableCell>
                <TableCell>{order.client_id}</TableCell>
                <TableCell>{order.out_trade_no}</TableCell>
                <TableCell>{order.payer_unionid}</TableCell>
                <TableCell style={{ position: 'sticky', right: 0, background: 'white' }}>
                  <Tooltip title="删除">
                    <IconButton onClick={() => handleDeleteConfirm(order.id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <Typography>您确定要删除这个订单吗？此操作不可撤销。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>取消</Button>
          <Button onClick={handleDelete} color="error">
            删除
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Orders;
import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, InputAdornment, Snackbar,
  Alert, CircularProgress, TablePagination, Tooltip, useMediaQuery, useTheme, TableSortLabel, Avatar
} from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { getLogins, createLogin, updateLogin, deleteLogin } from '../../services/loginService';
import Layout from '../../components/Layout';

interface Login {
  id: number;
  clientid: string;
  return_url: string;
  domain: string;
  original_json: string;
  from_qr: boolean;
  bypass_weixin: boolean;
  unionid: string;
  openid: string;
  nickname: string;
  headimgurl: string;
  error_msg: string;
  remote_ip: string;
  remote_ua: string;
  created_at: string;
  client_nickname: string;
  client_headimgurl: string;
}

type Order = 'asc' | 'desc';

const Logins: React.FC = () => {
  const [logins, setLogins] = useState<Login[]>([]);
  const [filteredLogins, setFilteredLogins] = useState<Login[]>([]);
  const [open, setOpen] = useState(false);
  const [currentLogin, setCurrentLogin] = useState<Login | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [loginToDelete, setLoginToDelete] = useState<number | null>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Login>('created_at');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 定义固定"操作"列的样式
  const operationsCellStyle = {
    position: 'sticky' as const,
    right: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
  };

  const fetchLogins = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getLogins();
      setLogins(data);
      setFilteredLogins(data);
    } catch (error) {
      setError('获取登录记录失败');
      console.error('Failed to fetch logins:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLogins();
  }, [fetchLogins]);

  useEffect(() => {
    const filtered = logins.filter(login =>
      login.clientid.toLowerCase().includes(searchTerm.toLowerCase()) ||
      login.domain.toLowerCase().includes(searchTerm.toLowerCase()) ||
      login.nickname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLogins(filtered);
    setPage(0);
  }, [searchTerm, logins]);

  const handleRequestSort = (property: keyof Login) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedLogins = React.useMemo(() => {
    const comparator = (a: Login, b: Login) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };

    return [...filteredLogins].sort((a, b) => {
      return order === 'desc' ? comparator(a, b) : -comparator(a, b);
    });
  }, [filteredLogins, order, orderBy]);

  const handleOpen = (login: Login | null) => {
    setCurrentLogin(login || { id: 0, clientid: '', return_url: '', domain: '', original_json: '', from_qr: false, bypass_weixin: false, unionid: '', openid: '', nickname: '', headimgurl: '', error_msg: '', remote_ip: '', remote_ua: '', created_at: '', client_nickname: '', client_headimgurl: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentLogin(null);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currentLogin) {
      setLoading(true);
      try {
        if (currentLogin.id) {
          await updateLogin(currentLogin.id, { error_msg: currentLogin.error_msg });
          setSnackbar({ open: true, message: '登录记录更新成功', severity: 'success' });
        } else {
          await createLogin(currentLogin);
          setSnackbar({ open: true, message: '登录记录创建成功', severity: 'success' });
        }
        fetchLogins();
        handleClose();
      } catch (error) {
        console.error('Failed to save login:', error);
        setSnackbar({ open: true, message: '保存登录记录失败', severity: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteConfirm = (id: number) => {
    setLoginToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    if (loginToDelete) {
      setLoading(true);
      try {
        await deleteLogin(loginToDelete);
        fetchLogins();
        setSnackbar({ open: true, message: '登录记录删除成功', severity: 'success' });
      } catch (error) {
        console.error('Failed to delete login:', error);
        setSnackbar({ open: true, message: '删除登录记录失败', severity: 'error' });
      } finally {
        setLoading(false);
        setDeleteConfirmOpen(false);
        setLoginToDelete(null);
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading && logins.length === 0) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Layout title="登录记录管理">
      <Typography variant="h4" gutterBottom>
        登录记录管理
      </Typography>
      <Paper sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          label="搜索登录记录"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(null)}
          startIcon={<AddIcon />}
        >
          添加登录记录
        </Button>
      </Paper>
      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>客户头像</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'client_nickname'}
                  direction={orderBy === 'client_nickname' ? order : 'asc'}
                  onClick={() => handleRequestSort('client_nickname')}
                >
                  客户昵称
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'domain'}
                  direction={orderBy === 'domain' ? order : 'asc'}
                  onClick={() => handleRequestSort('domain')}
                >
                  登录域名
                </TableSortLabel>
              </TableCell>
              <TableCell>登录头像</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'nickname'}
                  direction={orderBy === 'nickname' ? order : 'asc'}
                  onClick={() => handleRequestSort('nickname')}
                >
                  登录昵称
                </TableSortLabel>
              </TableCell>
              {!isMobile && (
                <>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'remote_ip'}
                      direction={orderBy === 'remote_ip' ? order : 'asc'}
                      onClick={() => handleRequestSort('remote_ip')}
                    >
                      IP地址
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'created_at'}
                      direction={orderBy === 'created_at' ? order : 'asc'}
                      onClick={() => handleRequestSort('created_at')}
                    >
                      创建时间
                    </TableSortLabel>
                  </TableCell>
                </>
              )}
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'clientid'}
                  direction={orderBy === 'clientid' ? order : 'asc'}
                  onClick={() => handleRequestSort('clientid')}
                >
                  Client ID
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ ...operationsCellStyle, whiteSpace: 'nowrap' }}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLogins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((login) => (
              <TableRow key={login.id}>
                <TableCell>
                  <Avatar src={login.client_headimgurl} alt={login.client_nickname} />
                </TableCell>
                <TableCell>{login.client_nickname}</TableCell>
                <TableCell>{login.domain}</TableCell>
                <TableCell>
                  <Avatar src={login.headimgurl} alt={login.nickname} />
                </TableCell>
                <TableCell>{login.nickname}</TableCell>
                {!isMobile && (
                  <>
                    <TableCell>{login.remote_ip}</TableCell>
                    <TableCell>{new Date(login.created_at + ' Z').toLocaleString()}</TableCell>
                  </>
                )}
                <TableCell>{login.clientid}</TableCell>
                <TableCell style={{ ...operationsCellStyle, whiteSpace: 'nowrap' }}>
                  <Tooltip title="编辑">
                    <IconButton onClick={() => handleOpen(login)} size="small">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="删除">
                    <IconButton onClick={() => handleDeleteConfirm(login.id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredLogins.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{currentLogin?.id ? '编辑登录记录' : '添加登录记录'}</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              margin="dense"
              label="Client ID"
              fullWidth
              value={currentLogin?.clientid || ''}
              onChange={(e) => setCurrentLogin({ ...currentLogin, clientid: e.target.value } as Login)}
              disabled={!!currentLogin?.id}
            />
            <TextField
              margin="dense"
              label="域名"
              fullWidth
              value={currentLogin?.domain || ''}
              onChange={(e) => setCurrentLogin({ ...currentLogin, domain: e.target.value } as Login)}
              disabled={!!currentLogin?.id}
            />
            <TextField
              margin="dense"
              label="错误信息"
              fullWidth
              value={currentLogin?.error_msg || ''}
              onChange={(e) => setCurrentLogin({ ...currentLogin, error_msg: e.target.value } as Login)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>取消</Button>
            <Button type="submit" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : '保存'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <Typography>您确定要删除这个登录记录吗？此操作不可撤销。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>取消</Button>
          <Button onClick={handleDelete} color="error">
            删除
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Logins;
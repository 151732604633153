import adminAxios from './adminAxios';

export const getClients = async () => {
  const response = await adminAxios.get(`/clients`);
  return response.data;
};

export const createClient = async (client: any) => {
  const response = await adminAxios.post(`/clients`, client);
  return response.data;
};

export const updateClient = async (unionid: string, client: any) => {
  const response = await adminAxios.put(`/clients/${unionid}`, client);
  return response.data;
};

export const deleteClient = async (unionid: string) => {
  const response = await adminAxios.delete(`/clients/${unionid}`);
  return response.data;
};

export const searchWeixinUsers = async (nickname: string) => {
  const response = await adminAxios.get(`/weixin-users/search?nickname=${encodeURIComponent(nickname)}`);
  return response.data;
};
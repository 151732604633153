import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, List, ListItem, ListItemText, Box, ListItemButton, IconButton, Drawer, useMediaQuery, CssBaseline, Divider } from '@mui/material';
import { Dashboard as DashboardIcon, People as PeopleIcon, Computer as ComputerIcon, Login as LoginIcon, Money as MoneyIcon } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Helmet } from 'react-helmet-async';

interface LayoutProps {
  children: React.ReactNode;
  title: string;
}

const menuItems = [
  { text: '仪表板', icon: <DashboardIcon />, path: '/admin/dashboard' },
  { text: '微信用户记录', icon: <PeopleIcon />, path: '/admin/weixin-users' },
  { text: '客户端管理', icon: <ComputerIcon />, path: '/admin/clients' },
  { text: '登录记录', icon: <LoginIcon />, path: '/admin/logins' },
  { text: '订单记录', icon: <MoneyIcon />, path: '/admin/orders' },
];

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {isMobile && (
        <>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h6" noWrap component="div">
            {`${title}`}
            </Typography>
          </Toolbar>
          <Divider />
        </>
      )}
      <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              sx={{
                textAlign: 'left',
                pl: 3,
              }}
            >
              {item.icon}
              <ListItemText primary={item.text} sx={{ ml: 2 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Helmet>
          <title>{`${title}`}</title>        
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        </Helmet>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + (isMobile ? 0 : 1),
            bgcolor: 'rgba(255, 255, 255, 0.85)',
            color: 'rgba(0, 0, 0, 0.77)',
            boxShadow: 'none',
            backdropFilter: 'blur(5px)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="打开菜单"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            <Drawer
              variant={isMobile ? "temporary" : "permanent"}
              open={isMobile ? mobileOpen : true}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                '& .MuiDrawer-paper': { 
                  boxSizing: 'border-box', 
                  width: drawerWidth,
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                  height: { xs: '100%', sm: '100%' },
                  top: { xs: 0, sm: '64px' },
                  zIndex: (theme) => theme.zIndex.drawer + (isMobile ? 2 : 0),
                  mt: { xs: 0, sm: 0 },
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              mt: ['48px', '56px', '64px'],
              overflowY: 'auto',
              height: 'calc(100vh - 64px)',
              bgcolor: '#f5f5f5',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, CircularProgress } from '@mui/material';
import Layout from '../../components/Layout';
import { getDashboardStats } from '../../services/dashboardService';

interface StatItem {
  total: number;
  todayNew: number;
  weekNew: number;
}

interface DashboardStats {
  clients: StatItem;
  users: StatItem;
  logins: StatItem;
  orders: StatItem;
  revenue: {
    total: number;
    todayNew: number;
    weekNew: number;
  };
}

const StatCard: React.FC<{ title: string, stat: StatItem }> = ({ title, stat }) => (
  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 180 }}>
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Typography variant="h4" component="div">
      {stat.total}
    </Typography>
    <Typography color="text.secondary" sx={{ mt: 1 }}>
      今日新增: {stat.todayNew}
    </Typography>
    <Typography color="text.secondary">
      七日新增: {stat.weekNew}
    </Typography>
  </Paper>
);

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<DashboardStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const data = await getDashboardStats();
        setStats(data);
        setLoading(false);
      } catch (err) {
        console.error('获取仪表板数据失败:', err);
        setError('获取仪表板数据失败');
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return (
      <Layout title="仪表板">
        <CircularProgress />
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout title="仪表板">
        <Typography color="error">{error}</Typography>
      </Layout>
    );
  }

  return (
    <Layout title="仪表板">
      <Typography variant="h4" gutterBottom>
        仪表板
      </Typography>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={4}>
          <StatCard title="用户统计" stat={stats!.users} />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard title="客户端统计" stat={stats!.clients} />
        </Grid>
        <Grid item xs={12} md={4}>
          <StatCard title="登录统计" stat={stats!.logins} />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 180 }}>
            <Typography variant="h6" gutterBottom>
              支付统计
            </Typography>
            <Typography variant="h4" component="div">
              {stats!.orders.total}笔，¥{stats!.revenue.total.toFixed(2)}
            </Typography>
            <Typography color="text.secondary" sx={{ mt: 1 }}>
              今日新增: {stats!.orders.todayNew}笔，¥{stats!.revenue.todayNew.toFixed(2)}
            </Typography>
            <Typography color="text.secondary">
              七日新增: {stats!.orders.weekNew}笔，¥{stats!.revenue.weekNew.toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
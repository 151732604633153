import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
  TablePagination,
  Tooltip,
  useMediaQuery,
  useTheme,
  TableSortLabel,
} from "@mui/material";
import {
  Search as SearchIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  getWeixinUsers,
  createWeixinUser,
  updateWeixinUser,
  deleteWeixinUser,
} from "../../services/weixinUserService";
import Layout from "../../components/Layout";

interface WeixinUser {
  unionid: string;
  nickname: string;
  headimgurl: string;
  created_at: string;
  updated_at: string;
  openid: string;
}

type Order = "asc" | "desc";

const WeixinUsers: React.FC = () => {
  const [users, setUsers] = useState<WeixinUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<WeixinUser[]>([]);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<WeixinUser | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof WeixinUser>("unionid");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // 定义固定"操作"列的样式
  const operationsCellStyle = {
    position: 'sticky' as const,
    right: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getWeixinUsers();
      setUsers(data);
      setFilteredUsers(data);
    } catch (error) {
      setError("获取微信用户失败");
      console.error("Failed to fetch weixin users:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    const filtered = users.filter(
      (user) =>
        user.unionid.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.openid.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.nickname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    setPage(0);
  }, [searchTerm, users]);

  const handleRequestSort = (property: keyof WeixinUser) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedUsers = React.useMemo(() => {
    const comparator = (a: WeixinUser, b: WeixinUser) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };

    return [...filteredUsers].sort((a, b) => {
      return order === "desc" ? comparator(a, b) : -comparator(a, b);
    });
  }, [filteredUsers, order, orderBy]);

  const handleOpen = (user: WeixinUser | null) => {
    setCurrentUser(
      user || {
        unionid: "",
        openid: "",
        nickname: "",
        headimgurl: "",
        created_at: "",
        updated_at: "",
      }
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentUser(null);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currentUser) {
      setLoading(true);
      try {
        if (currentUser.unionid) {
          await updateWeixinUser(currentUser.unionid, currentUser);
          setSnackbar({
            open: true,
            message: "微信用户更新成功",
            severity: "success",
          });
        } else {
          await createWeixinUser(currentUser);
          setSnackbar({
            open: true,
            message: "微信用户创建成功",
            severity: "success",
          });
        }
        fetchUsers();
        handleClose();
      } catch (error) {
        console.error("Failed to save weixin user:", error);
        setSnackbar({
          open: true,
          message: "保存微信用户失败",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteConfirm = (unionid: string) => {
    setUserToDelete(unionid);
    setDeleteConfirmOpen(true);
  };

  const handleDelete = async () => {
    if (userToDelete) {
      setLoading(true);
      try {
        await deleteWeixinUser(userToDelete);
        fetchUsers();
        setSnackbar({
          open: true,
          message: "微信用户删除成功",
          severity: "success",
        });
      } catch (error) {
        console.error("Failed to delete weixin user:", error);
        setSnackbar({
          open: true,
          message: "删除微信用户失败",
          severity: "error",
        });
      } finally {
        setLoading(false);
        setDeleteConfirmOpen(false);
        setUserToDelete(null);
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString + "Z").toLocaleString();
  };

  if (loading && users.length === 0) {
    return (
      <Container
        maxWidth="lg"
        sx={{ mt: 4, mb: 4, display: "flex", justifyContent: "center" }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Layout title="微信用户管理">
      <Typography variant="h4" gutterBottom>
        微信用户管理
      </Typography>
      <Paper
        sx={{
          p: 2,
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          label="搜索微信用户"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen(null)}
          startIcon={<AddIcon />}
        >
          添加微信用户
        </Button>
      </Paper>
      <TableContainer component={Paper} sx={{ position: 'relative' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "unionid"}
                  direction={orderBy === "unionid" ? order : "asc"}
                  onClick={() => handleRequestSort("unionid")}
                >
                  UnionID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "nickname"}
                  direction={orderBy === "nickname" ? order : "asc"}
                  onClick={() => handleRequestSort("nickname")}
                >
                  昵称
                </TableSortLabel>
              </TableCell>
              <TableCell>头像</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "created_at"}
                  direction={orderBy === "created_at" ? order : "asc"}
                  onClick={() => handleRequestSort("created_at")}
                >
                  创建时间
                </TableSortLabel>
              </TableCell>
              {!isMobile && (
                <>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "updated_at"}
                      direction={orderBy === "updated_at" ? order : "asc"}
                      onClick={() => handleRequestSort("updated_at")}
                    >
                      最近登录时间
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "openid"}
                      direction={orderBy === "openid" ? order : "asc"}
                      onClick={() => handleRequestSort("openid")}
                    >
                      OpenID
                    </TableSortLabel>
                  </TableCell>
                </>
              )}
              <TableCell style={{ ...operationsCellStyle, whiteSpace: 'nowrap' }}>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.unionid}>
                  <TableCell>{user.unionid}</TableCell>
                  <TableCell>{user.nickname}</TableCell>
                  <TableCell>
                    <img
                      src={user.headimgurl}
                      alt={user.nickname}
                      style={{ width: 50, height: 50, borderRadius: "50%" }}
                    />
                  </TableCell>
                  <TableCell>{formatDate(user.created_at)}</TableCell>
                  {!isMobile && (
                    <>
                      <TableCell>{formatDate(user.updated_at)}</TableCell>
                      <TableCell>{user.openid}</TableCell>
                    </>
                  )}
                  <TableCell style={{ ...operationsCellStyle, whiteSpace: 'nowrap' }}>
                    <Tooltip title="编辑">
                      <IconButton onClick={() => handleOpen(user)} size="small">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="删除">
                      <IconButton
                        onClick={() => handleDeleteConfirm(user.unionid)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {currentUser?.unionid ? "编辑微信用户" : "添加微信用户"}
        </DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="UnionID"
              fullWidth
              value={currentUser?.unionid || ""}
              onChange={(e) =>
                setCurrentUser({
                  ...currentUser,
                  unionid: e.target.value,
                } as WeixinUser)
              }
              disabled={!!currentUser?.unionid}
              required
            />
            <TextField
              margin="dense"
              label="OpenID"
              fullWidth
              value={currentUser?.openid || ""}
              onChange={(e) =>
                setCurrentUser({
                  ...currentUser,
                  openid: e.target.value,
                } as WeixinUser)
              }
              required
            />
            <TextField
              margin="dense"
              label="昵称"
              fullWidth
              value={currentUser?.nickname || ""}
              onChange={(e) =>
                setCurrentUser({
                  ...currentUser,
                  nickname: e.target.value,
                } as WeixinUser)
              }
              required
            />
            <TextField
              margin="dense"
              label="头像URL"
              fullWidth
              value={currentUser?.headimgurl || ""}
              onChange={(e) =>
                setCurrentUser({
                  ...currentUser,
                  headimgurl: e.target.value,
                } as WeixinUser)
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>取消</Button>
            <Button type="submit" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : "保存"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>确认删除</DialogTitle>
        <DialogContent>
          <Typography>您确定要删除这个微信用户吗？此操作不可撤销。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>取消</Button>
          <Button onClick={handleDelete} color="error">
            删除
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default WeixinUsers;
import adminAxios from './adminAxios';

export const getOrders = async (
  page: number,
  rowsPerPage: number,
  orderBy: string,
  order: 'asc' | 'desc',
  searchTerm: string
) => {
  const response = await adminAxios.get(`/orders`, {
    params: {
      page,
      rowsPerPage,
      orderBy,
      order,
      searchTerm
    }
  });
  return response.data;
};

export const createOrder = async (order: any) => {
  const response = await adminAxios.post(`/orders`, order);
  return response.data;
};

export const updateOrder = async (id: number, order: any) => {
  const response = await adminAxios.put(`/orders/${id}`, order);
  return response.data;
};

export const deleteOrder = async (id: number) => {
  const response = await adminAxios.delete(`/orders/${id}`);
  return response.data;
};
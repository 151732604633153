import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const Home: React.FC = () => {
  console.log('Home');
  const [hasClient, setHasClient] = useState<boolean | null>(null);
  const [clientInfo, setClientInfo] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchHomeInfo = async () => {
      try {
        const response = await axios.get('/api/home');
        setHasClient(response.data.hasClient);
        if (response.data.hasClient) {
          setClientInfo(response.data.clientInfo);
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          // 用户未登录,重定向到登录页面
          const currentPath = encodeURIComponent(window.location.href);
          window.location.href = `/login?return_url=${currentPath}`;
        }
        else{
          console.error('获取首页信息失败:', error);
        }
      }
    };

    fetchHomeInfo();
  }, [navigate, location]);

  if (hasClient === null) {
    return <div>加载中...</div>;
  }

  return (
    <div>
      {hasClient ? (
        <div>
          <h1>欢迎回来</h1>
          <p>您的客户信息如下：</p>
          <pre>{JSON.stringify(clientInfo, null, 2)}</pre>
        </div>
      ) : (
        <div>
          <h1>欢迎使用我们的服务</h1>
          <p>我们提供以下服务：</p>
          <ul>
            <li>微信登录服务</li>
            <li>微信支付服务</li>
          </ul>
          <p>您还没有创建客户账户，是否要创建一个？</p>
          <Link to="/create-client">创建客户账户</Link>
        </div>
      )}
    </div>
  );
};

export default Home;